<template>
  <div>
    <b-modal
      v-model="open"
      :title="`Detalhes da tarefa`"
      centered
      hide-footer
      hide-header-close
    >
      <b-card
        :title="`${item.title}`"
        :sub-title="`Responsável: ${item.username}`"
        border-variant="warning"
        footer-tag="footer"
      >
        <b-card-text>
          Projeto: <b>{{ item.project_code }} - {{ item.project_name }}</b
          ><br />
          Execução: <b>{{ item.start }} - {{ item.end }}</b
          ><br />
          Data: <b>{{ formatDate }}</b> <br />
          Prazo para execução:
          <b>{{ this.eventDuration(item.end, item.start) }}</b> <br /><br />
          Notas: {{ item.notes }}<br />
          despesa fixa da tarefa:
          <b>R$ {{ numberToMonetary(item.indirect_cost) }}</b> <br /><br />
          <b>
            ETAPA NO PROJETO:
            {{
              item && item.project_stage ? item.project_stage.stage_name : ''
            }}</b
          >
        </b-card-text>
        <template #footer>
          <b-link href="#" class="card-link" @click="openEditModal"
            >Editar terefa</b-link
          >
          <b-link href="#" class="card-link" @click="closeEditModal"
            >Voltar</b-link
          >
        </template>
      </b-card>
    </b-modal>

    <b-modal
      ref="editModal"
      :title="`Detalhes da tarefa`"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header-close
    >
      <!-- Formulario -->
      <validation-observer ref="ModalProjectCosts">
        <hr class="invoice-spacing mb-3" />
        <b-form>
          <b-row
            class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
          >
            <!-- PROJETOS -->
            <b-form-group class="mb-2">
              <label for="category" class="font-weight-bold text-primary"
                >Projetos :</label
              >
              <b-form-select id="category" v-model="item.project_id">
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.project_code }} - {{ project.project_name }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="mb-2" v-if="item.project_id">
              <label for="category" class="font-weight-bold text-primary"
                >Etapa do projeto :</label
              ><br />
              <b-form-select
                v-model="item.project_stage_id"
                :options="project_stages"
                value-field="id"
                text-field="stage_name"
                placeholder="Selecione uma etapa"
              ></b-form-select>
            </b-form-group>

            <!-- TITULO -->
            <b-form-group class="mb-2">
              <label class="font-weight-bold text-primary">Titulo:</label>
              <b-form-input v-model="item.title"></b-form-input>
            </b-form-group>

            <!-- DATA DE REFERÊNCIA -->
            <b-form-group class="mb-2" :disabled="item.isRepeatActive">
              <label for="referenceDate" class="font-weight-bold text-primary"
                >Data :</label
              >
              <b-form-input
                id="referenceDate"
                type="date"
                v-model="item.date"
              ></b-form-input>
            </b-form-group>

            <!-- HORA DE INÍCIO -->
            <b-form-group class="mb-2">
              <label for="startTime" class="font-weight-bold text-primary"
                >Hora de Início:</label
              >
              <b-form-input
                maxlength="5"
                id="startTime"
                type="time"
                value="09:20"
                v-model="item.start"
                @input="item.start = formatHour($event)"
              ></b-form-input>
            </b-form-group>

            <!-- HORA DE FIM -->
            <b-form-group class="mb-2">
              <label for="endTime" class="font-weight-bold text-primary"
                >Hora de Fim:</label
              >
              <b-form-input
                maxlength="5"
                id="endTime"
                type="time"
                v-model="item.end"
                @input="item.end = formatHour($event)"
              ></b-form-input>
            </b-form-group>

            <!-- Descricao -->
            <b-form-group class="mb-2">
              <label class="font-weight-bold text-primary">Descriçao:</label>
              <b-form-textarea v-model="item.notes"></b-form-textarea>
            </b-form-group>
          </b-row>

          <hr class="invoice-spacing mb-3" />
        </b-form>
      </validation-observer>

      <!-- Botoes -->
      <b-row class="justify-content-around d-flex">
        <!-- back -->
        <b-button variant="warning" class="mr-1" @click="closeEditModal"
          >Voltar
        </b-button>
        <b-button variant="danger" @click="confirmDeleteModal" class="mr-1"
          >Deletar
        </b-button>
        <!-- back -->
        <b-button @click="saveTask" class="mr-1">Salvar </b-button>
      </b-row>

      <b-modal ref="modal-confirm" hide-footer centered title="Um momento">
        <p class="my-4">Deseja mesmo excluir a tarefa?</p>
        <hr />
        <b-row class="justify-content-around d-flex">
          <b-button variant="danger" class="mr-2" @click="deleteTask"
            >Sim
          </b-button>
          <b-button variant="warning" class="mr-2" @click="hideDeleteModal"
            >Não
          </b-button>
        </b-row>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormSelect,
  BButton,
  BFormInput,
  BFormTextarea,
  BCard,
  BCardText,
  BLink,
} from 'bootstrap-vue';
import { differenceInMinutes, format, parseISO } from 'date-fns';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
//import moment from 'moment'

export default {
  name: 'new-task',
  props: {
    _item: { type: Object, require: true },
    open: {
      type: Boolean,
      default: false,
    },
    costs_variables: { type: Object, require: false },
  },
  components: {
    BModal,
    BForm,
    BRow,
    BFormGroup,
    BFormSelect,
    BButton,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      workspace_name: '',
      item: {
        project_id: null,
        title: '',
        date: null,
        start: '',
        end: '',
        notes: '',
        direct_cost: '100',
        real_direct_cost: '100',
        indirect_cost: '100',
        real_indirect_cost: '100',
      },
      member_id: '',
      projects: [],
      project_stages:[],
      loading: false,
    };
  },

  created() {
    this.getMemberId();
    this.loadProjects();
    this.workspace_name = this.$store.getters.currentWorkspace.workspace_title;
  },

  computed: {
    formatDate() {
      return this.open ? format(parseISO(this.item.date), 'dd/MM/yyyy') : '';
    },
  },

  watch: {
    _item: {
      handler(newPreTime) {
        this.item = {
          ...newPreTime,
          start: newPreTime.startTime.slice(0, 5),
          end: newPreTime.endTime.slice(0, 5),
          date: this.dateFormatter(newPreTime.date, 'yyyy-MM-dd'),
        };
      },
      deep: true,
    },
  },

  methods: {
    openEditModal() {
      this.$nextTick(() => {
        this.$refs.editModal.show();
        this.getProjectStages()
      });
    },
    closeEditModal() {
      this.$refs.editModal.hide();
      this.$emit('closeModal');
    },
    confirmDeleteModal() {
      this.$refs['modal-confirm'].show();
    },
    hideDeleteModal() {
      this.$refs['modal-confirm'].hide();
    },
    async getMemberId() {
      this.$store
        .dispatch('getUserMember', {
          id: this.$store.getters.user.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.member_id = resp[0].member_id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveTask() {
      const startTime = this.item.start.split(':');
      const endTime = this.item.end.split(':');

      const startDate = new Date();
      startDate.setHours(startTime[0], startTime[1]);

      const endDate = new Date();
      endDate.setHours(endTime[0], endTime[1]);

      if (endDate <= startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hora de fim deve ser maior que a hora de início',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        });
        return;
      }

      //const redate = moment(this.item.date).utcOffset(-6, true);

      this.item.start = this.transformInCompleteData(this.item.start);
      this.item.end = this.transformInCompleteData(this.item.end);

      const duration = (endDate - startDate) / (1000 * 60 * 60);

      this.$store
        .dispatch('updateMyTask', {
          id: this.item.id,
          project_id: this.item.project_id,
          project_stage_id: this.item.project_stage_id,
          title: this.item.title,
          notes: this.item.notes,
          date: this.item.date,
          start: this.item.start,
          indirect_cost: Math.round(
            (this.costs_variables.average_office_cost /
              this.costs_variables.hour_per_month) *
              duration
          ),
          end: this.item.end,
          updated_at: new Date(),
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa cadastrada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.closeEditModal();
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'ErrorIcon',
              variant: 'danger',
              text: err.response?.data?.message,
            },
          });
          console.log(err);
        });
    },

    async deleteTask() {
      this.$store
        .dispatch('deleteTask', {
          id: this.item.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa excluida com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.closeEditModal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async loadProjects() {
      try {
        await this.$store
          .dispatch('_getProjects', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            this.projects = resp.data;
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },
    async getProjectStages() {
      this.project_stages = [];
      try {
        await this.$store
          .dispatch('getProjectStages', {
            project_id: this.item.project_id,
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            this.project_stages = resp.map((item) => ({
              id: item.id,
              stage_name: item.stage_name,
            }));
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },

    transformNumberInHour(numero) {
      if (numero >= 0 && numero <= 23) {
        const horasFormatadas = numero < 10 ? `0${numero}` : `${numero}`;
        return `${horasFormatadas}:00`;
      } else {
        return 'Número inválido. Por favor, insira um número de 0 a 23.';
      }
    },

    transformInCompleteData(hour) {
      const dataAtual = new Date(this.item.date);
      dataAtual.setDate(dataAtual.getDate() + 1);
      dataAtual.setHours(hour.split(':')[0]);
      dataAtual.setMinutes(hour.split(':')[1]);

      return this.dateFormatter(dataAtual, 'yyyy-MM-dd HH:mm:ss');
    },

    formatHour(hour) {
      return hour;
    },
    onlyHours(dataString) {
      const data = new Date(dataString);
      data.setHours(data.getHours());

      const horas = data.getHours();
      const minutos = data.getMinutes();

      const horasFormatadas = horas < 10 ? '0' + horas : horas;
      const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

      return `${horasFormatadas}:${minutosFormatados}`;
    },

    eventDuration(endTime, startTime) {
      const end = new Date(`01 january 2024 ${endTime} GMT-3`);
      const start = new Date(`01 january 2024 ${startTime} GMT-3`);

      const totalMinutes = differenceInMinutes(end, start);

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    },
  },
};
</script>

<style>
.card-subtitle {
  color: #000 !important;
}
</style>
