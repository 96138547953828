<template>
  <div>
    <header>
      <BCard title="Ações">
        <b-row class="justify-content-center">
          <b-col cols="6">
            <b-button class="mt-2" block variant="success" @click="openModalAction">+ Criar nova tarefa</b-button>
            <!-- DATA INI -->
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="category" class="font-weight-bold text-primary">Ir para data:</label>
              <b-form-input
                id="transactionDate"
                type="date"
                v-model="start_week_day"
                @change="updateSchedulerWeek"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </BCard>
    </header>
    <BCard>
      <!-- CODIGO PARA SER IMPLEMENTADO -->

      <div class="calendar-wrapper">
        <vue-scheduler
          :key="refresh"
          ref="scheduler"
          class="custom-scheduler"
          locale="pt-br"
          :min-date="null"
          :max-date="null"
          :labels="{
            today: 'Hoje',
            back: 'Anterior',
            next: 'Seguinte',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            all_day: 'Todos os dias'
          }"
          :time-range="[0, 23]"
          :available-views="[]"
          :active-date="schedulerInitialDate"
          initial-view="week"
          :showTodayButton="true"
          :events="events"
          :event-display="eventDisplay"
          disableDialog
          @month-changed="monthChanged"
          @week-changed="weekChanged"
          @day-changed="dayChanged"
          @view-changed="viewChanged"
          @day-clicked="dayClicked"
          @time-clicked="timeClicked"
          @event-clicked="eventClicked"
          @event-created="eventCreated"
        />
      </div>
      <!-- <DayPilotCalendar /> -->
      <Modal :pre_time="preset_data" :costs_variables="costs_variables" @closeModal="closeModal" :open.sync="isModalOpen" />
      <ModalUpdate :_item="eventSelect" :costs_variables="costs_variables" @closeModal="closeModal" :open="modalUpdate" />
    </BCard>
  </div>
</template>

<script>
import { BCard, BButton, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import Modal from './Modal.vue';
import ModalUpdate from './Update.vue';
import 'v-calendar-scheduler/lib/main.css';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BButton,
    Modal,
    BCol,
    ModalUpdate
  },
  data() {
    return {
      isModalOpen: false,
      refresh: 0,
      start_week_day: '',
      end_week_day: '',
      schedulerInitialDate: null,
      events: [],
      project_stages: [],
      startDate: new Date(),
      endDate: new Date(),
      preset_data: { start: '', end: '', date: '' },
      modalOpen: false,
      modalUpdate: false,
      currentDate: new Date(),
      member: {},
      eventSelect: {},
      costs_variables: {
        average_office_cost: '',
        hour_per_month: ''
      },

      timeSelect: '',
      dateSelect: ''
    };
  },

  async created() {
    await this.getPastYearOfficeCost();
    await this.getMemberInfo();
    await this.getWeekDates();

    this.$nextTick(() => {
      const calendarWrapper = this.$el.querySelector('.v-cal-content .v-cal-days');
      if (calendarWrapper) {
        calendarWrapper.scrollTop = calendarWrapper.scrollHeight * 0.5; // Ajusta para 80% rolada
      }
    });
  },

  methods: {
   
    async getProjectStages() {
      this.project_stages = [];
      this.$store
        .dispatch('getProjectStages', {
          project_id: this.item.project_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_stages = resp.map((item) => ({
            id: item.id,
            stage_name: item.stage_name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMemberInfo() {
      await this.$store
        .dispatch('getUserMember', {
          id: this.$store.getters.user.id,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(async resp => {
          this.member.id = resp[0].member_id;
          this.costs_variables.hour_per_month = resp[0] && resp[0].member ? resp[0].member.hour_per_month : 0
          await this.getTasks();
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getTasks() {
      this.$store
        .dispatch('getMyTasks', {
          start_date: this.start_week_day,
          end_date: this.end_week_day,
          member_id: this.member.id,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          this.items = resp.data;

          this.events = [];
          if (resp === '') {
            this.items = [];
          }

          this.items.map(dialog => {
            this.events.push({
              ...dialog,
              color: dialog.member.color,
              project_code: dialog.project.project_code,
              project_name: dialog.project.project_name,
              date: dialog.date,
              startTime: dialog.start,
              endTime: dialog.end,
              name: dialog.title,
              comments: dialog.notes,
              username: dialog.member.access.user.username
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    formatTime(timeString) {
      //console.log('string de tempo',timeString)
      const time = new Date(timeString).toISOString().slice(11, 16);
      console.log('string de tempo',time)

      return time;
    },

    onlyHours(dataString) {
      const data = new Date(dataString);
      data.setHours(data.getHours());

      const horas = data.getHours();
      const minutos = data.getMinutes();

      const horasFormatadas = horas < 10 ? '0' + horas : horas;
      const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

      return `${horasFormatadas}:${minutosFormatados}`;
    },

    eventDisplay(event) {
      const display = `${event.project_code} - ${event.name}\nInicio: ${this.onlyHours(event.startTime)}\nFim: ${this.onlyHours(
        event.endTime
      )}\n\nNotas: ${event.notes === null ? '' : event.notes}`;
      // eslint-disable-next-line quotes
      return display;
    },

    previousWeek() {
      const newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() - 7);
      this.$set(this, 'currentDate', newDate);

      this.getTasks();
    },
    nextWeek() {
      const newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() + 7);
      this.$set(this, 'currentDate', newDate);

      this.getTasks();
    },

    openModalUpdateAction() {
      this.modalUpdate = true;
    },
    openModalAction() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.modalUpdate = false;
      this.events = [];
      this.getTasks();
    },
    getWeekDates() {
      var today = new Date();
      var dayOfWeek = today.getDay();

      var daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek;

      var startDate = new Date(today);
      startDate.setDate(today.getDate() - daysToMonday);

      var endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      this.startDate = startDate;
      this.endDate = endDate;

      this.start_week_day = this.dateFormatter(startDate, 'yyyy-MM-dd');
      this.end_week_day = this.dateFormatter(endDate, 'yyyy-MM-dd');
    },

    monthChanged(newDate) {
      console.log(newDate);
    },
    weekChanged(newDate) {
      this.schedulerInitialDate = typeof newDate === 'string' ? new Date(newDate) : newDate;
      const today = new Date(this.schedulerInitialDate);

      const dayOfWeek = today.getDay();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - dayOfWeek);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      this.start_week_day = this.dateFormatter(startDate, 'yyyy-MM-dd');
      this.end_week_day = this.dateFormatter(endDate, 'yyyy-MM-dd');

      this.getTasks();
    },

    dayChanged(newDate) {
      console.log('Day Changed');
      console.log(newDate);
    },
    viewChanged(newView) {
      console.log('View Changed');
      console.log(newView);
    },
    dayClicked(date) {
      console.log('Day clicked');
      console.log(date.getDate());
    },
    timeClicked(dateWithTime) {
      this.preset_data.date = dateWithTime.date;
      this.preset_data.start = dateWithTime.time;
      this.preset_data.end = dateWithTime.time + 1;
      this.openModalAction();
    },
    eventClicked(event) {
      this.eventSelect = event;
      this.openModalUpdateAction();
    },
    eventCreated(event) {
      console.log('Event created');
      console.log(event);
    },
    updateSchedulerWeek() {
      if (this.$refs.scheduler) {
        this.schedulerInitialDate = new Date(this.start_week_day);
        this.schedulerInitialDate.setHours(this.schedulerInitialDate.getHours() + 3);

        this.schedulerInitialDate.setDate(this.schedulerInitialDate.getDate());

        this.$nextTick(() => {
          if (this.$refs.scheduler) {
            this.$refs.scheduler.$emit('week-changed', this.schedulerInitialDate);
            // console.log(this.$refs.scheduler.activeDate);
            this.$refs.scheduler.activeDate = moment(this.schedulerInitialDate);
          }
        });
      }
    },
    async getPastYearOfficeCost() {
      this.$store
        .dispatch('getPastYearOfficeCost', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          group: 'offices',
          expense: true
        })
        .then((resp) => {
          this.costs_variables.average_office_cost = resp.averageValue;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .calendar-wrapper {
    height: 95vh !important;
    overflow: auto;
  }
  .custom-scheduler {
    height: 95vh !important;
    padding-left: 0 !important;
    width: 160vw;
  }
  .v-cal-header {
    padding-bottom: 12px;
  }
  .v-cal-header__actions {
    -webkit-box-pack: justify;
    justify-content: start;
    margin-bottom: 15px;
  }
  .v-cal-content.v-cal-content--month .v-cal-weekdays,
  .v-cal-content.v-cal-content--week .v-cal-weekdays,
  .v-cal-content.v-cal-content--day .v-cal-weekdays {
    font-size: 0.7rem;
  }
  .v-cal-content.v-cal-content--week .v-cal-times,
  .v-cal-content.v-cal-content--day .v-cal-times {
    font-size: 0.65rem;
  }

  .custom-select {
    font-size: 0.7rem;
  }
  .btn-list {
    font-size: 0.7rem;
  }
  .v-cal-header__title-bar .v-cal-header__title {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: start;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .v-cal-event-name {
    font-size: 0.7rem;
  }
  .v-cal-button {
    font-size: 0.7rem;
  }
  .v-cal-hour.all-day {
    display: none !important;
  }
}
.v-cal-weekdays {
  position: sticky;
}

.v-cal-content .v-cal-days {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

.v-cal-content .v-cal-event-item .v-cal-event-time {
  margin: 0;
  display: none;
  overflow-wrap: break-word;
}

.v-cal-content .v-cal-event-item .v-cal-event-name {
  margin-left: 0;
  text-overflow: ellipsis;
}
.v-cal-content .v-cal-event-item {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.custom-scheduler {
  height: 95vh !important;
  padding-left: 0 !important;
}
.v-cal-weekdays {
  position: sticky;
}

.v-cal-content .v-cal-days {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-cal-content .v-cal-event-item .v-cal-event-time {
  margin: 0;
  display: none;
  overflow-wrap: break-word;
}

.v-cal-content .v-cal-event-item .v-cal-event-name {
  margin-left: 0;
  text-overflow: ellipsis;
}
.v-cal-content .v-cal-event-item {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.custom-scheduler {
  height: 95vh !important;
}
</style>
